import {
	PUBLIC_SENTRY_DSN,
	PUBLIC_SST_STAGE,
	PUBLIC_VERSION,
	PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID
} from '$env/static/public'
import * as Sentry from '@sentry/svelte'
import type { HandleClientError, NavigationEvent } from '@sveltejs/kit'
import securify from '@shop-ware/securify'
import { Logger } from '@shop-ware/logger'
import * as FeatureFlags from '@shop-ware/svelte-feature-flags'
import { nanoid } from 'nanoid'

const logger = new Logger('hooks')

const ERROR_CODE_MAP = {
	BROWSER_SETTINGS: 'operation is insecure',
	NETWORK_FETCH: 'Failed to fetch',
	NETWORK_PRELOAD: 'Unable to preload',
	NETWORK_SEND: "Failed to execute 'send'",
	NETWORK_IMPORT: 'Importing a module script',
	NETWORK: 'network error'
}

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	environment: PUBLIC_SST_STAGE,
	release: PUBLIC_VERSION,
	ignoreErrors: Object.values(ERROR_CODE_MAP),
	integrations: [
		new Sentry.BrowserTracing({
			beforeNavigate: context => {
				return securify(context)
			}
		})
	],
	tracesSampleRate: 0.2,
	beforeSend(event) {
		return securify(event)
	},
	beforeBreadcrumb(breadcrumb) {
		return securify(breadcrumb)
	},
	beforeSendTransaction(event) {
		return securify(event)
	}
})

FeatureFlags.init(PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID, {
	kind: 'user',
	anonymous: true
})

export const handleError: HandleClientError = ({
	error,
	event
}: {
	error: any
	event: NavigationEvent
}) => {
	logger.error(error)

	const errorId = nanoid()
	const normalizedMessage = error?.message?.toLowerCase()

	if (normalizedMessage) {
		const match = Object.entries(ERROR_CODE_MAP).find(([_, value]) =>
			normalizedMessage.includes(value.toLowerCase())
		)

		if (match) {
			return {
				message:
					'An error occurred, please see the error code for more details.',
				code: match[0],
				errorId
			}
		}
	}

	Sentry.captureException(error, { extra: { event, errorId } })

	return {
		message: 'An error occurred, please see the error code for more details.',
		code: 'UNKNOWN',
		errorId
	}
}
